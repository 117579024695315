@font-face {
    font-family: Sf-Pro-Display-Bold;
    src: url(../fonts/SFPRODISPLAYBOLD.OTF);
  }
  @font-face {
    font-family: Sf-Pro-Display-Regular;
    src: url(../fonts/SFPRODISPLAYREGULAR.OTF);
}
*{
    transition:all 0.1s ease-in;
}
/* :root {
    --b-btn-sign-color-bg-one:#231b30;
    --b-btn-sign-color-bg-two:#221b2f;
    --b-btn-color-text:#FFF;
    --b-btn-color-text:#FFF;
    --a-color:#FFF;
} */
[data-theme="dark"] {
    --b-btn-sign-color-bg-one:#231b30;
    --b-btn-sign-color-bg-two:#221b2f;
    --b-nav-link:#FFF;
    --b-btn-color-text:#FFF;
    --a-color:#FFF;
    --b-input-focus:#FFF;
    --b-placholder-color:#FFF;
    --b-active-bg-color-one:#231b30;
    --b-active-bg-color-two: #221b2f;
    
    background: url('../images/bg.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
[data-theme="light"] {
    --b-btn-sign-color-bg-one:#FFF;
    --b-btn-sign-color-bg-two:#FFF;
    --b-btn-color-text:#000;
    --b-nav-link:#000;
    --a-color:#000;
    --b-input-focus:#000;
    --b-placholder-color:#000;
    --b-active-bg-color-one:#d3d3d3;
    --b-active-bg-color-two:#dcc9ff;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
}
/* body {
   background: url('../images/bg.png');
   height: 100%;
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
} */
::selection {
    background:#733EC7;
    color: #FFF;
}
a{
    color:var(--a-color);
    text-decoration: none;
}
input:focus{
    border: 1px solid var( --b-input-focus);
    box-shadow: none;
}
.form-control:focus {
    border: 1px solid var( --b-input-focus);
    box-shadow: none;
}
.Sf-Pro-Display-Regular{
    font-family: Sf-Pro-Display-Regular;
}
.Sf-Pro-Display-Bold{
    font-family: Sf-Pro-Display-Bold;
}
/* commponent button  */
.btn-button{
    background:linear-gradient(#231b30, #221b2f) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border-radius:0.6rem;
    border: 2px solid transparent;
    color:#FFF!important; 
}
/*  */
/* Navbar Here*/
.navbar-bg{
    font-family: Sf-Pro-Display-Regular;
}
.navbar-bg .navbar-nav .nav-link{
    color:var(--b-nav-link);
    font-size: 0.9rem;
    letter-spacing: 0.6px;
}
.navbar-bg .navbar-nav .nav-link .active{
    color:#FFF;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
}
.navbar-bg .navbar-toggler i{
    color:#FFF;
    border: none;
    font-size: 2.2rem;
}
.navbar-bg .navbar-toggler {
    box-shadow: none;
   border: none;
}
.mobile-block1231098 {
    display: none;
}
.navbar-bg .navbar-brand img {
    width: 120px;
    height: 100%;
}
.navbar-bg .navbar-nav{
    gap: 20px;
}
.navbar-bg .user-block-542 a{
    color:var(--a-color);
    font-family: Sf-Pro-Display-Regular;
    font-weight: 400;
    font-size: 0.9rem;
}
.navbar-bg a .btn-sign{
    background: linear-gradient(var(--b-btn-sign-color-bg-one), var(--b-btn-sign-color-bg-two)) padding-box, linear-gradient(120deg,#733EC7, #CA62DB) border-box;
    border-radius:0.6rem;
    border: 2px solid transparent;
    color:var(--b-btn-color-text);
    width: 100px;
}
.navbar-bg  .toggle-wrap{
    display: flex;
}
.navbar-bg .toggle-switch{
    width: 0;
    height: 0;
    visibility: hidden;
}
.navbar-bg .toggle{
    position: relative;
    width: 71px;
    height: 35px;
    border-radius: 100px;
    background-color: #dee2e6;
    transition: 0.3s ease-in-out;
}
.navbar-bg .toggle::after{
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 29px;
    height: 30px;
    border-radius: 50%;
    background-color: #2E1752;
    transition: 0.3s ease-in-out;
}
.navbar-bg .toggle i{
    position: absolute;
    font-size:40px;
    z-index: 1;
    transition: 0.3s ease-in-out;
}
.navbar-bg .toggle i:first-child {
    top: 7px;
    left: 8px;
    color:#FFF;
    font-size: 0.9rem;
}
.navbar-bg .toggle i:last-child {
    top: 6px;
    right: 9px;
    color: #000;
    font-size:0.9rem;
}
.navbar-bg .toggle-switch:checked ~ .toggle .bi-moon{
    color:#FFF;
} 
.navbar-bg .toggle-switch:checked ~ .toggle .bi-sun{ 
    color: #000;
}
.navbar-bg .toggle-switch:checked ~ .toggle::after {
  transform: translateX(40px);
} 
/* End Navbar */
/* slider Here */
.slider-block312 {
    text-align: center;
    margin: auto;
    width: 100%;
    color:var(--a-color);
    font-size: 1.0rem;
    height: 40px;
    padding-top: 8px;
    border-top: 1px solid #3B2662;
    border-bottom: 1px solid #3B2662;
}
.slider-block312 .swiper-button-next {
    background-image:url(../images/arrow-left.svg), linear-gradient(90deg, #180C1A 56.64%, #180C1A 207.58%);
    background-repeat: no-repeat;
    top: 35px;
    right: 0px;
    width: 40px;
    height: 25px;
}
.slider-block312 .swiper-button-next::after {
    display: none;
}
.slider-block312 .swiper-button-prev {
    background-image:url(../images/arrow-right.svg), linear-gradient(90deg, #180C1A 56.64%, rgba(27, 14, 46, 0) 207.58%);
    background-repeat: no-repeat;
    top: 35px;
    left: 0px;
    width: 40px;
    height: 25px;
}
.slider-block312 .swiper-button-prev::after {
    display: none;
}
/* slider End */
/* Hero Section  Here*/
.conent-block-343 {
    margin-top: 5.5em;
    color:var(--a-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.conent-block-343 h2 {
    width: 85%;
    font-weight: 800;
    font-size: 3.6rem;
    letter-spacing: 0.1rem;
    line-height: 4.2rem;
    font-family: 'Sf-Pro-Display-Bold';
}
.conent-block-343 h2 span{
    background: -webkit-linear-gradient(47deg,#733EC7, #CA62DB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Sf-Pro-Display-Bold;
}
.conent-block-343 p{
    width: 65%;
    margin-top: 1.4em;
    margin-bottom: 1.2em;
    font-weight: 400!important;
    font-size: 1.1rem;
    letter-spacing: 0.1px;
}
.image-astronaut{
    width: 12%;
    height: auto;
    position: absolute;
    top: 35%;
    right: 0;
}
.image-astronaut{
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
.image-astronaut{
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-8px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-8px); }
}
.user-search-block-language{
    width: 100%;
    margin-top: 1em;
}
.user-search-block-language input {
    height: 48px;
  background-color: transparent!important;
  border-radius: 0.5rem;
  color:var(--b-placholder-color)!important;
}
.user-search-block-language input::placeholder {
  color:var(--b-placholder-color);
  font-size:1.0rem;
  font-weight: 400;
}
/* .user-search-block-language input:focus {
 border: 1px solid#FFF;
} */
.user-search-block-language .btn-search {
    background-color: #733EC7;
    color:#FFF;
    padding: 0.5rem 1.4rem 0.5rem 1.4rem;
    font-size: 1.0rem;
    font-weight: 400;
    font-family: 'Sf-Pro-Display-Regular';
    letter-spacing: 0.5px;
   border-radius: 0.5rem;
}
.user-select-category-box {
    background: rgba(107, 107, 107, 0.1);
    border-radius: 0.9rem;
    padding: 1.4rem 1rem 1.4rem;
    font-weight: 500;
    border-radius: 1.2rem;
    cursor: pointer;
}
.user-select-category-box  .active{
    background: linear-gradient(#231b30, #221b2f) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border: 2px solid transparent;
}
.user-select-category-box h4 {
    font-size:1.0rem;
    color:var(--a-color);
}
.active-box {
    background: linear-gradient(var(--b-active-bg-color-one), var(--b-active-bg-color-two)) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border: 2px solid transparent;
}
.sapce-ship{
    position: absolute;
    left: 0%;
    width: 20%;
    margin-top: 70px;
}
.sapce-ship{
    -webkit-animation: sapce-ship 1s infinite  alternate;
    animation: sapce-ship 1s infinite  alternate;
}
.sapce-ship{
    -webkit-animation: sapce-ship 1s infinite  alternate;
    animation: sapce-ship 1s infinite  alternate;
}
@-webkit-keyframes sapce-ship {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes sapce-ship {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
.rocket{
    position: absolute;
    right: 30px;
    margin-top: 15px;
    width: 20%;
}
.rocket{
    -webkit-animation: rocket 2s infinite  alternate;
    animation: rocket 2s infinite  alternate;
}
.rocket{
    -webkit-animation: rocket 2s infinite  alternate;
    animation: rocket 2s infinite  alternate;
}
@-webkit-keyframes rocket {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes rocket {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
.jupiter-bg img {
    width: 100%;
    height: 80%;
    /* margin-top: 7.1em; */
}

/* Hero Section  End*/
/* Courses Section Here */
.courses {
    margin-top: -25em;
    color:#FFF;
    text-align: center;
}
.courses h3{
    font-size: 2.6rem;
    font-family: Sf-Pro-Display-Bold;
}
.courses h5{
    font-size: 1.2rem;
    margin-top: 8px;
    font-family: Sf-Pro-Display-Regular;
    letter-spacing: 1.0px;
}
.courses h3 span{
    background: -webkit-linear-gradient(47deg,#733EC7, #CA62DB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 5px;
    margin-right: 5px;
}
.language-block-765{
 margin-top: 3em;
}
/* Courses Section End */
/* Two Column Here*/
.terminologies-block-6879 h3{
    color:#FFF;
    font-size: 2.1rem;
    font-family: Sf-Pro-Display-Bold;
    line-height: 2.6rem;
}
.terminologies-block-6879 h3 span{
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.terminologies-block-6879 h4 {
    color: #0079FF;
    font-size: 1.1rem;
    margin-top: 15px;
    font-family: Sf-Pro-Display-Regular;
}
.terminologies-block-6879 p {
    color:#FFF;
    font-family: Sf-Pro-Display-Regular;
    font-size: 1.0rem;
    width: 70%;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-top: 17px;
}
.sapce-human{
    width: 100%;
    position: absolute;
    right: 85px;
    top: -65px;
}
.sapce-human{
    -webkit-animation: rocket 1s infinite  alternate;
    animation: sapcehuman 1s infinite  alternate;
}
.sapce-human{
    -webkit-animation: rocket 1s infinite  alternate;
    animation: sapcehuman 1s infinite  alternate;
}
@-webkit-keyframes sapcehuman {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes sapcehuman {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
/* Courses Section Here*/
.courses-block-8657 {
    margin-top: 13.2em;
}
.courses-block-8657 h3{
    color:#FFF;
    font-family: Sf-Pro-Display-Bold;
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
}
.courses-block-8657 h3 span {
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.courses-block-8657 .card-active {
    border-width: 2px;
    border-color: #733ec7 #ca62db #ca62db #ca62db;
  border-radius: 1.5rem;
}
.courses-block-8657 .card{
    background: rgba(107, 107, 107, 0.1);
    padding: 0.9rem;
    color:#FFF;
    height: 95%;
    border-radius: 1.5rem;
}
.courses-block-8657 .card-rounded::before{ 
  border-radius: 1.5rem;
}
.courses-block-8657 .card-title {
    font-family: Sf-Pro-Display-Bold;
    font-size: 1.3rem;
    letter-spacing: 0.2px;
    font-weight: 500;
}
.courses-block-8657 .card-text {
    font-family: Sf-Pro-Display-Regular;
    font-size: 0.9rem;
    width: 90%;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.courses-block-8657 img {
    border-radius: 1.3rem;
    height: 190px;
    object-fit: cover;
}
/* Courses Section End*/
/* Projects Section Here */
.projects-block-2312 {
    margin-top: 10.9em;
    color:#FFF;
}
.projects-block-2312 .projects{
    font-size: 2.4rem;
    font-family: Sf-Pro-Display-Bold;
}
.projects-block-2312 h3 span {
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.projects-card {
    background: rgba(107, 107, 107, 0.1);
    border-radius: 1.5rem;
    width: 100%;
}
.projects-card .projects-card-img img{
    border-radius: 1.3rem;
    object-fit: cover;
    height: 125px;
    width: 100%;
}
.projects-block-2312 h3 {
    font-size: 1.4rem;
    font-family: Sf-Pro-Display-Regular;
}
.projects-block-2312 p {
    width: 70%;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Sf-Pro-Display-Regular;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
}
.projects-block-2312 .active-card-project{
    background: linear-gradient(#231b30, #221b2f) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border: 2px solid transparent;
}
/* Projects Section End */
/* Business Section End */
.business-block-0129 {
    margin-top: 9.5em;
    text-align: center;
    color:#FFF;
}
.business-block-0129 figure h3{
    font-size: 2.4rem;
    font-family: Sf-Pro-Display-Bold;
    color:#FFF;
}
.business-block-0129 figure h3 span {
    background:-webkit-linear-gradient(90deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.business-block-0129 .card{
    background: rgba(107, 107, 107, 0.1);
    border-radius: 1.5rem;
    height: 350px;
    border: 1px solid #000;
}
.business-block-0129 .card .bussiness-card-img{
    width: 90px;
    background: rgba(107, 107, 107, 0.1);
    padding: 0.9rem;
    border-radius: 0.9rem;
    margin: auto;
    margin-top: 1.9em;
    height: 85px;
    padding: 1.3rem;
}
.business-block-0129 .card .card-title{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-family: 'Sf-Pro-Display-Bold';
    font-weight: 400;
    letter-spacing: 0.4px;
    width: 75%;
    margin: auto;
    padding-bottom: 15px;
}
.business-block-0129 .card .card-text{
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.4px;
}
/* Business Section End */
/* Video Section Here */
.video-block-87121 {
    margin-top: 12.8em;
}
.video-block-87121 figure h3{
    color:#FFF;
    font-family: 'Sf-Pro-Display-Bold';
    font-size: 1.9rem;
    letter-spacing: 0.6px;
    width: 70%;
}
.video-block-87121 figure h3 span {
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.video-block-87121 figure p {
    color:#FFF;
    font-family: Sf-Pro-Display-Regular;
    font-size: 1.0rem;
    font-weight: 400;
    letter-spacing: 0.3px;
   width: 80%;
}
.video-block-87121 video{
    border-radius: 1.5rem;
    height: 100%;
    width: 100%;
    margin-top: 25px;
}
.video-block-87121 .play-btn {
    width: 100px;
    height: 100px;
    background: radial-gradient( rgb(255 255 255) 60%, rgb(255 255 255 / 89%) 62%);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0px 0px 25px 3px #733ec7;
    left: 40%;
    right: 40%;
    top: 33%;
}
.video-block-87121 .play-btn:before {
    content: "";
    position: absolute;
    width: 130px;
    height: 130px;
    animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgb(255 255 255 / 0%);
    top: -15px;
    left: -16px;
    background: rgb(255 255 255 / 75%);
  }
  .video-block-87121 .video .play-icon{
    font-size: 2.8rem;
    left: 29px;
    position: absolute;
    top: 15px;
    cursor: pointer;
    color: #000;
    background:-moz-linear-gradient(91.62deg, #733EC7 54.26%, #CA62DB 72.98%); 
    background: -webkit-linear-gradient(91.62deg, #733EC7 54.26%, #CA62DB 72.98%); 
    background: linear-gradient(91.62deg, #733EC7 54.26%, #CA62DB 72.98%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
   -webkit-text-fill-color:transparent;
  }
  @-webkit-keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }
  @keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }
/* Video Section End */
/* Pricing Section Here */
.pricing-block-0921 {
    margin-top: 9.8em;
}
.pricing-block-0921 figure h3{
    color:#FFF;
    font-size: 1.9rem;
    letter-spacing: 0.6px;
}
.pricing-block-0921 figure h3 span {
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pricing-block-0921 figure p {
    color:#FFF;
    font-weight: 400;
    letter-spacing: 0.7px;
}
.pricing-annualy-monthly-block {
    color:#FFF;
}
.pricing-annualy-monthly-block .form-switch .form-check-input {
    width: 4.5em;
    height: 2.1em;
    border: none;
    box-shadow: none;
}
.pricing-annualy-monthly-block .form-switch .form-check-input { 
    border: none;
    box-shadow: none;
}
.pricing-block-0921 .pricing-card {
    background: rgba(107, 107, 107, 0.1);
    border-radius: 1.5rem;
    color:#FFF;
    height: 42rem;
}
.pricing-block-0921  .pricing-card-body .img-svg-icon{
    background-color: rgb(60 44 62 / 19%);
    padding: 15px;
    border-radius: 1rem;
}
.pricing-block-0921 .pricing-card-body .pricing-card-header img {
   width: 35px;
   height: auto;
}
.pricing-block-0921 .pricing-card-body .popular-btn{
    position: absolute;
    right: 25px;
    top: 15px;
    border: none;
    outline: none;
    background-color: #bb8cc726;
    padding: 0.3rem 1rem 0.3rem 1rem;
    color: #FFF;
    border-radius: 1.5rem;
    letter-spacing: 0.6px;
    font-size: 0.9rem;
}
.pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
    font-family: Sf-Pro-Display-Regular;
    font-size: 1.4rem;
}
.pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
    font-family: Sf-Pro-Display-Bold;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.3px;
}
.pricing-block-0921 .pricing-card-body .pricing-detail p {
  color:#FFF;
  font-size: 1.1rem;
  font-family: Sf-Pro-Display-Regular;
  letter-spacing: 0.6px;
}
.pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
    background:-webkit-linear-gradient(190deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Sf-Pro-Display-Bold;
    font-size: 4.3rem;
}
.pricing-block-0921 .active-card-pricing{
    background: linear-gradient(#231b30, #221b2f) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border: 2px solid transparent;
}
.pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
    color:#FFF;
  font-family: Sf-Pro-Display-Regular;
  font-size: 1.5rem;
}
.pricing-block-0921 .pricing-card-body .pricing-detail figure .list-item {
    list-style: none;
}
.pricing-block-0921 .pricing-card-body .pricing-detail figure .list-item span i{
    color:#733EC7;
}
/* Pricing Section End */
/* Testimonial Section Here */
.testimonial-bloclk-0129 {
    color:#FFF;
    margin-top: 10.8em;
    font-weight: 500;
}
.testimonial-bloclk-0129 h3 {
    font-size: 2.1rem;
}
.testimonial-bloclk-0129 h3 span{
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.testimonial-bloclk-0129 .testimonial-card{
    background: rgba(107, 107, 107, 0.1);
    border-radius: 1.5rem;
}
.testimonial-bloclk-0129 .testimonial-card .testimonial-card-detail p{
    font-size: 1.2rem;
   letter-spacing: 0.5px;
   width: 90%;
}
.testimonial-bloclk-0129 .testimonial-card .testimonial-card-user .testimonial-user-img img{
    width: 60px;
    object-fit: cover;
    border-radius: 1.5rem;
}
.testimonial-bloclk-0129 .testimonial-card .testimonial-card-user .testmonial-user-name .user-bio{ 
  color: #733EC7;
}
.testimonial-bloclk-0129 .mySwiper{
    height: 26rem;
}
.testimonial-bloclk-0129 .swiper-pagination-bullet-active {
    background: linear-gradient(93.95deg, #733EC7 4.05%, #CA62DB 95.1%);
    width: 50px;
    height: 11px;
    border-radius: 1rem;
}
.testimonial-bloclk-0129 .swiper-pagination-bullet{
    background-color:#FFF;
    opacity: 1;
}
/* Testimonial Section End */
/* Articale Section Here */
.articles-block-019212 .article-card{
    height: 15.5rem;
    padding: 1.7rem 1.7rem 1.6rem 1.7rem;
}
.articles-block-019212 {
    margin-top:9.1em;
}
.articles-block-019212 .article {
    color:#FFF;
    font-size: 2.2rem;
}
.articles-block-019212 h3 span{
    background:-webkit-linear-gradient(180deg, #eb6fff, #733EC7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.articles-block-019212 .article-card{
    /* background: rgba(107, 107, 107, 0.1); */
    background: linear-gradient(40deg, #733ec72e, rgba(107, 107, 107, 0.1));
    border-radius: 1.5rem;
}
.articles-block-019212 .date{
    font-size: 1.0rem;
    color: #8345CA;
    letter-spacing: 0.6px;
}
.articles-block-019212 .article-card  .article-content h3{
    color:#FFF;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 2.3rem;
}
.articles-block-019212  .article-user-name{
  color:#FFF;
  font-size: 1.0rem;
  margin-top:2.4em;
  letter-spacing: 0.2px;
}
.articles-block-019212 .article-card .article-img img{
    width: 450px;
    height: 220px;
    object-fit: cover;
    border-radius: 1.5rem;
}
.articles-block-019212 .article-card .article-row{
    align-items: end;
 }
.articles-block-019212 .card{
    background: linear-gradient(45deg, #ca62db26, #733ec721);
    border-radius: 1.5rem;
    padding-bottom: 15px;
    width: 100%;
}
.articles-block-019212 .card .card-text h3{
    font-size: 1.5rem;
    line-height: 1.9rem;
}
.articles-block-019212 .card .card-img img{
    object-fit: cover;
    border-radius: 1.5rem;
    height: 14rem;
    width: 100%;
}
.articles-block-019212 .article-content {
    padding-left: 1.4rem;
}
/*  */
.card-box-019282 {
    background: linear-gradient(#341b46, #241431);
    border-radius: 1.5rem;
    margin-top: 15.4em;
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    background: linear-gradient(#231b30, #221b2f) padding-box, linear-gradient(90deg,#733EC7, #CA62DB) border-box;
    border: 2px solid transparent;
}
.card-box-019282 .bookOpen{
    background: rgba(107, 107, 107, 0.1);
    padding: 1.1rem;
    border-radius: 0.9rem;
    width: 60px;
    height: 60px;
    text-align: center;
}
.card-box-019282  figure a   .read{
    font-size: 1.5rem;
    color: #CA62DB;
}
.card-box-019282  figure p {
    color:#FFF;
   font-size: 1.0rem;
}
/*  */
/* Articale Section End  */
/* Footer Section Here*/
.footer-block-section {
    margin-top: 5.8em;
}
.footer-logo img{
   width: 140px;
}
.footer-block-section .footer-about{
    color:#FFF;
    font-size: 1.0rem;
    letter-spacing: 0.4px;
    width:65%;
}
.footer-copyright{
    background-color: #733EC7;
   color:#FFF;
   margin-top: 3.1em;
}
.footer-copyright{ 
    letter-spacing: 0.3px;
}
.footer-h {
    color:#FFF;
    font-size: 1.3rem;
}
.list-group {
    list-style: none;
}
/* End Footer Section */
body {
    overflow-y: scroll;
    overflow-x:hidden;
}  
body::-webkit-scrollbar {
    width: 20px;
    height: 90px;
}  
body::-webkit-scrollbar-thumb {
    background:linear-gradient(#733EC7, #CA62DB);
}  
body::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
}