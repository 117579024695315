@media screen and (max-width: 1024.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .image-astronaut {
        width: 19%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 3px;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: 13px;
        width: 26%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 30%;
        margin-top: 15px;
    }
    .terminologies-block-6879 p {
        width: 100%;
    }
    .video-block-87121 figure h3 {
        width: 100%;
    }
    .video-block-87121 figure p {
        width: 100%;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 5em;
    }
    .courses h3 {
        font-size: 2.9rem;
    }
    .courses h5 {
        font-size: 1.1rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 35%;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        top: -22px;
        left: -22px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 1.8rem;
        left: 23px;
        position: absolute;
        top: 15px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 14rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 320px;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
    .pricing-block-0921 .pricing-card-body .popular-btn{
        position: absolute;
        right: 8px;
        top: 10px;
        padding: 0.3rem 1rem 0.3rem 1rem;
        border-radius: 1.5rem;
        letter-spacing: 0.6px;
        font-size: 0.7rem;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 768.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 110px;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 70%;
        margin: auto;
    }
    .image-astronaut {
        width: 19%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 3px;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: 13px;
        width: 26%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 30%;
        margin-top: 15px;
    }
    .terminologies-block-6879 p {
        width: 100%;
    }
    .video-block-87121 figure h3 {
        width: 100%;
    }
    .video-block-87121 figure p {
        width: 100%;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 5em;
    }
    .courses h3 {
        font-size: 2.9rem;
    }
    .courses h5 {
        font-size: 1.1rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 35%;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        top: -21px;
        left: -21px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 1.8rem;
        left: 23px;
        position: absolute;
        top: 15px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 14rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 320px;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
    .pricing-block-0921 .pricing-card-body .popular-btn{
        position: absolute;
        right: 8px;
        top: 10px;
        padding: 0.3rem 1.1rem 0.3rem 1.1rem;
        border-radius: 1.5rem;
        letter-spacing: 0.6px;
        font-size: 0.9rem;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 600.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 75px;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .image-astronaut {
        width: 24%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 0;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        width: 35%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 35%;
        margin-top: -15px;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 0em;
    }
    .courses h3 {
        font-size: 1.7rem;
    }
    .courses h5 {
        font-size: 0.9rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 35%;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        top: -18px;
        left: -18px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 1.8rem;
        left: 23px;
        position: absolute;
        top: 15px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 23rem;
        padding: 1.7rem 1.7rem 1.6rem 1.7rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 320px;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 575.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 75px;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .image-astronaut {
        width: 24%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 0;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        width: 35%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 35%;
        margin-top: -15px;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 0em;
    }
    .courses h3 {
        font-size: 1.7rem;
    }
    .courses h5 {
        font-size: 0.9rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .article-card .article-row{
       align-items: center;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 35%;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        top: -18px;
        left: -18px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 1.8rem;
        left: 23px;
        position: absolute;
        top: 15px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 23rem;
        padding: 1.7rem 1.7rem 1.6rem 1.7rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 320px;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 480.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 75px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .image-astronaut {
        width: 24%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 0;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        width: 35%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 35%;
        margin-top: -15px;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 4em;
    }
    .courses h3 {
        font-size: 1.7rem;
    }
    .courses h5 {
        font-size: 0.9rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 25px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 35%;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        top: -21px;
        left: -21px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 2.0rem;
        left: 23px;
        position: absolute;
        top: 13px;
        cursor: pointer;
    }
    .
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 23rem;
        padding: 1.7rem 1.7rem 1.6rem 1.7rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 86vw;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
    .pricing-block-0921 .pricing-card-body .popular-btn{
        position: absolute;
        right: 8px;
        top: 10px;
        padding: 0.3rem 1.1rem 0.3rem 1.1rem;
        border-radius: 1.5rem;
        letter-spacing: 0.6px;
        font-size: 0.9rem;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 375.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 75px;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .image-astronaut {
        width: 24%;
        height: auto;
        position: absolute;
        top: 40%;
        right: 0;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        width: 35%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 35%;
        margin-top: -15px;
    }
    .courses {
        margin-top: -5em;
    }   
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 5em;
    }
    .courses h3 {
        font-size: 1.7rem;
    }
    .courses h5 {
        font-size: 0.9rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 70px;
        height: 70px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 95px;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        top: -22px;
        left: -22px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 2.0rem;
        left: 20px;
        position: absolute;
        top: 10px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button {
        font-size: 1.0rem;
        padding-left: 1.4rem!important;
        padding-right: 1.4rem!important;
    }
    .articles-block-019212 .article-card {
        height: 23rem;
        padding: 1.7rem 1.7rem 1.6rem 1.7rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 320px;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
    .pricing-block-0921 .pricing-card-body .popular-btn{
        position: absolute;
        right: 8px;
        top: 10px;
        padding: 0.3rem 0.8rem 0.3rem 0.8rem;
        border-radius: 1.5rem;
        letter-spacing: 0.6px;
        font-size: 0.7rem;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}
@media screen and (max-width: 320.98px) {

    /* Section One Responsive Here*/
    .conent-block-343 {
        margin-top: 1.6em;
    }
    .conent-block-343 h2 {
        width: 100%;
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    .desktop-block1231098 {
        display:none;
    }
    .mobile-block1231098  {
        display: block;
        position: absolute;
        top: 22px;
        right: 75px;
    }
    .conent-block-343 p {
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.9px;
    }
    .user-search-block-language {
        width: 100%;
        margin: auto;
    }
    .image-astronaut {
        width: 24%;
        height: auto;
        position: absolute;
        top: 49%;
        right: 0;
    }
    .user-select-category-box {
        margin-bottom: 20px;
    }
    .rocket {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        width: 35%;
    }
    .sapce-ship {
        position: absolute;
        left: -7%;
        width: 35%;
        margin-top: -15px;
    }
    .courses {
        margin-top: -3em;
    }
    .jupiter-bg img {
        width: 100%;
        height: 80%;
        margin-top: 5em;
    }
    .courses h3 {
        font-size: 1.7rem;
    }
    .courses h5 {
        font-size: 0.9rem;
        width: 80%;
        margin: auto;
        line-height: 1.4rem;
    }
    .language-block-765 img{
        margin-bottom: 2.4em;
    }
    .sapce-astronte {
        margin-top: 4.3em;
    }
    .sapce-astronte {
        margin-top: 2.3em;
    }
    .sapce-human {
        width: 100%;
        position: absolute;
        right: 5px;
        top: 20px;
    }
    .sapce-astronte .square-image{
      width: 100%;
    }
    .courses-block-8657 {
        margin-top: 4.2em;
    }
    .projects-block-2312 {
        margin-top: 4.2em;
    }
    .projects-card-img {
        margin-bottom: 1.5em;
    }
    .projects-card {
        width: 100%;
        margin: auto;
    }
    .business-block-0129 .card {
        margin-bottom: 1.2em;
    }
    .video-block-87121 {
        margin-top: 1.1em;
    }
    .video-block-87121 .play-btn {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 40%;
        right: 40%;
        top: 85px;
    }
    .video-block-87121 .play-btn:before {
        position: absolute;
        width: 95px;
        height: 95px;
        border-radius: 50%;
        top: -18px;
        left: -18px;
    }
    .video-block-87121 .video .play-icon {
        font-size: 1.8rem;
        left: 17px;
        position: absolute;
        top: 8px;
        cursor: pointer;
    }
    .video-block-87121 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 {
        margin-top: 4.2em;
    }
    .pricing-block-0921 figure p {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h4 {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-card-header h3 {
        font-size: 1.3rem;
        letter-spacing: 0.2px;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail p {
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        line-height: 1.4rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span p {
        font-size: 1.1rem;
    }
    .pricing-block-0921 .pricing-card-body .pricing-detail figure span h3 {
        font-size: 3.2rem;
    }
    .pricing-block-0921    .list-item li {
        font-size: 0.9rem;
    }
    .pricing-block-0921 .pricing-card {
        border-radius: 1.5rem;
        height: 36.8rem;
        margin-bottom: 1.4em;
        padding: 2rem!important;
    }
    .testimonial-bloclk-0129 {
        margin-top: 4.2em;
    }
    .articles-block-019212 .btn-button{
        font-size: 0.7rem;
        padding-left: 0.9rem!important;
        padding-right: 0.9rem!important;
    }
    .articles-block-019212 .article-card {
        height: 25rem;
        padding: 1.7rem 1.7rem 1.6rem 1.7rem;
    }
    .articles-block-019212 .article-card .article-img img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 1.5rem;
    }
    .articles-block-019212 .card {
        padding-bottom: 15px;
        margin-bottom: 1.5em;
    }
    .articles-block-019212 .card .card-text h3 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .articles-block-019212 .article-card .article-content h3 {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .articles-block-019212 .article {
        font-size: 1.6rem;
    }
    .card-box-019282 {
        border-radius: 1.5rem;
        margin-top: 4.2em;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .card-box-019282 figure p {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
    }
    .pricing-block-0921 .pricing-card-body .popular-btn{
        position: absolute;
        right: 8px;
        top: 10px;
        padding: 0.3rem 0.8rem 0.3rem 0.8rem;
        border-radius: 1.5rem;
        letter-spacing: 0.6px;
        font-size: 0.7rem;
    }
     /* Section One Responsive End*/
   /* Footer Section Here */
   .footer-block-section .footer-about {
        font-size: 1.0rem;
        letter-spacing: 0.4px;
        width: 100%;
    }
    .footer-block-section .list-group li a{
        font-size: 0.9rem;
    }
    .footer-copyright  p {
        font-size: 0.9rem!important;
    }
   /* Footer Section End */
}